.amgdprjs-bar-template {
    .amgdprcookie-bar-container {
        .amgdprcookie-buttons-block {
            flex-direction: row-reverse;
            justify-content: space-between;

            .amgdprcookie-button.-allow,
            .amgdprcookie-button.-decline,
            .amgdprcookie-button.-settings {
                font-size: 0.8rem;
                line-height: 1rem;
                flex-basis: 58%;
            }
        }
    }
}
