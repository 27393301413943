.page-footer {
    .footer-part {
        .footer-content {
            width: $container-lg;

            .footer-copyright {
                text-align: right;
            }
        }
    }
}

