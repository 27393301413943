.product-items {
    display: flex;
    flex-wrap: wrap;
    //.product-item-info .product-item-photo .product-image-container .product-image-wrapper img {
    //    height: 8rem;
    //}
}
.product-item {
    .product-item-info {
        .product-item-name {
            height: 41px;
            line-height: 41px;
            margin-top: 0;
            a {
                font-size: 0.9rem;
            }
        }
        .product-item-inner {
            .product-item-action {
                .action-primary {
                    font-size: 1rem;
                }
            }
        }
    }
    .product-item > a img {
        right: 240px;
        left: initial;
    }
    .product-item > a img {
        right: 240px;
        left: initial;
    }
}
