// Breakpoints
$screen-hd: 1680px;
$screen-lg: 1440px;
$screen-md: 1024px;
$screen-sm: 769px;
$screen-xs: 425px;
$screen-xxs: 354px;

// Container width
$container-hd: 1200px;
$container-lg: 1020px;
$container-md: 980px;
$container-sm: 100%;
$container-xs: 100%;

//Common font-sizes
$big-slider-font-size: 26px;
$normal-font-size: 15px;
$small-font-size: 12px;

//Common line-heights
$big-slider-line-height: 36px;

// Font Awesome Fonts
$fa: 'gameo-fa';
$fab: 'gameo-fa';
$fak: 'gameo-fa';

// Colors
$white: #fff;
$light: #f8f7f2;
$grey-light: #d2d2d2;
$grey: #a7a7a8;
$grey-medium: #666;
$grey-dark: #646465;
$light-dark: #373738;
$btn-dark: #272729;
$dark-1: #212123;
$dark-2: #1f1f20;
$very-dark-1: #1b1b1c;
$very-dark-2: #161616;
$black: #000;

$turquoise: #00fff1;
$turquoise-opacity: rgba($turquoise, 0.35);
$turquoise-very-dark: #125e6b;
$cyan-turquoise: #00f1ff;
$cyan: #03dcff;
$green-dark: #2ad2c9;
$purple: #6b00ff;
$purple-light: #9700e2;
$purple-light-opacity: rgba(#9700e2, 0.5);
$purple-dark: #4a25aa;
$pink: #ff009c;
$pink-light: #ff58be;
$pink-dark: #c6057b;
$pink-very-dark: #6b1149;
$magenta: #bb00c2;
$pink-opacity: rgba(255, 0, 156, 0.35);
$blue: #2879f8;
$blue-light: #03a9ff;
$purple-blue: #4d3fff;
$emerald-light: #2ad2c980;
$emerald-opacity: rgba(19, 94, 107, 0.35);
$emarald-dark: #31494d;

// Prefixers
$prefix-for-webkit: true !default;
$prefix-for-mozilla: true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera: true !default;
$prefix-for-spec: true !default;

// Gradient
@mixin dark-cyan-gradient {
    background: transparent
        radial-gradient(
            farthest-side at 97% 100%,
            rgba(32, 32, 34, 0.6) 0%,
            rgba(32, 32, 34, 0.6) 19%,
            rgba(22, 106, 121, 0.6) 75%,
            rgba(5, 222, 255, 0.6) 100%
        )
        0% 0% no-repeat;
}

@mixin dark-cyan-linear-gradient {
    background: transparent
        linear-gradient(
            62deg,
            rgba(31, 31, 32, 0.6),
            rgba(33, 33, 35, 0.6) 19%,
            rgba(22, 104, 119, 0.6) 75%,
            rgba(3, 220, 255, 0.6) 100%
        )
        0% 0% no-repeat padding-box;
}

@mixin turquoise-blue-gradient {
    background: transparent
        linear-gradient(120deg, $turquoise 0%, $turquoise 27%, $cyan 49%, $cyan 100%) 0% 0%
        no-repeat padding-box;
}
@mixin blue-turquoise-gradient {
    background: transparent
        linear-gradient(117deg, $cyan 0%, $cyan 27%, $turquoise 49%, $turquoise 100%) 0% 0%
        no-repeat padding-box;
}
@mixin blue-gradient {
    background: transparent linear-gradient(89deg, #00788b 0%, $purple-blue 100%) 0% 0% no-repeat
        padding-box;
}
@mixin cyan-gradient {
    background: transparent linear-gradient(123deg, $cyan 0%, $purple-blue 100%) 0% 0% no-repeat
        padding-box;
}
@mixin cyan-blue-gradient-317deg {
    background: transparent linear-gradient(317deg, $cyan 0%, $purple-blue 100%) 0% 0% no-repeat
        padding-box;
}

@mixin cyan-blue-gradient-127deg {
    background: transparent linear-gradient(127deg, $cyan 0%, $purple-blue 100%) 0% 0% no-repeat
        padding-box;
}

@mixin green-blue-gradient {
    background: transparent
        linear-gradient(90deg, #099aa2 0%, #099aa2c9 18%, #464efab3 85%, $purple-blue 100%) 0% 0%
        no-repeat padding-box;
}

@mixin transparent-pink-purple-gradient {
    background: transparent linear-gradient(91deg, #ff009c99 0%, #8b00e9b3 64%, #6b00ffcc 100%) 0%
        0% no-repeat padding-box;
}

@mixin pink-purple-gradient {
    background: transparent linear-gradient(113deg, $pink 0%, #8b00e9 64%, $purple 100%) 0% 0%
        no-repeat padding-box;
}
@mixin pink-gradient {
    background: transparent linear-gradient(90deg, $pink 0%, $pink 48%, $purple-light 100%) 0% 0%
        no-repeat padding-box;
}
@mixin pink-dark-ellipse-gradient {
    background: transparent
        radial-gradient(ellipse farthest-corner at 8% 5%, $pink 0%, $dark-2 63%, $dark-2 100%) 0% 0%
        no-repeat;
}
@mixin pink-purple-green-blue-gradient {
    background: transparent
        linear-gradient(90deg, $pink 0%, $purple-dark 70%, $blue 85%, $blue 100%) 0% 0% no-repeat
        padding-box;
}
@mixin pink-purple-green-blue-gradient-reverse {
    background: transparent
        linear-gradient(90deg, $blue 0%, $blue 70%, $purple-dark 85%, $pink 100%) 0% 0% no-repeat
        padding-box;
}
@mixin pink-blue-green-purple-gradient {
    background: transparent
        linear-gradient(90deg, $blue 0%, $blue 10%, $purple-dark 30%, $pink 100%) 0% 0% no-repeat
        padding-box;
}
@mixin pink-purple-gradient-44deg {
    background: transparent linear-gradient(44deg, $pink 0%, #8b00e9 49%, $purple 100%) 0% 0%
        no-repeat padding-box;
}
@mixin purple-pink-gradient {
    background: transparent linear-gradient(317deg, #6e00ff 0%, #ee00ff 100%) 0% 0% no-repeat
        padding-box;
}
@mixin turquoise-dark-gradient {
    background: transparent
        linear-gradient(
            360deg,
            $cyan-turquoise 0%,
            $cyan 15%,
            #166776 33%,
            $very-dark-1 75%,
            $very-dark-1 100%
        )
        0% 0% no-repeat padding-box;
}
@mixin turquoise-dark-gradient-reverse {
    background: transparent
        linear-gradient(
            135deg,
            #00f1ff99 0%,
            #03dcff99 15%,
            #166776 33%,
            #1b1b1c99 75%,
            #1b1b1c99 100%
        )
        0% 0% no-repeat padding-box;
}
@mixin pink-dark-gradient {
    background: transparent
        linear-gradient(
            360deg,
            $pink 0%,
            $pink 15%,
            #ff009c 33%,
            $very-dark-1 75%,
            $very-dark-1 100%
        )
        0% 0% no-repeat padding-box;
}
@mixin pink-dark-gradient-revert {
    background: transparent
        linear-gradient(
            135deg,
            #ff009c99 0%,
            #ff009c99 15%,
            #ff009c99 33%,
            #1b1b1c99 75%,
            #1b1b1c99 100%
        )
        0% 0% no-repeat padding-box;
}

@mixin black-gradient {
    background: transparent linear-gradient(90deg, #1b1b1c 0%, #1f1f20 100%) 0% 0% no-repeat
        padding-box;
}

// Border gradients
// To use it, import the mixin and define the border-side expected
// i.e. to define a left border gradient, 2px wide:
// @include pink-purple-border-gradient;
// border-left: 2px solid;
@mixin turquoise-cyan-border-gradient-lr {
    border-image-slice: 1;
    border-image-source: linear-gradient(
        90deg,
        $turquoise 0%,
        $cyan-turquoise 20%,
        $cyan 66%,
        #0283ff 100%
    );
}
@mixin pink-purple-border-gradient-lr {
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, $pink 0%, #8b00e9 20%, #6e00fd 66%, $purple 100%);
}

@mixin turquoise-cyan-border-gradient-bt {
    border-image-slice: 1;
    border-image-source: linear-gradient(
        180deg,
        $turquoise 0%,
        $cyan-turquoise 20%,
        $cyan 66%,
        #0283ff 100%
    );
}

@mixin turquoise-cyan-border-gradient-tlbr {
    border-image-slice: 1;
    border-image-source: linear-gradient(
        5deg,
        rgba($cyan-turquoise, 0.5) 20%,
        #0283ff 50%,
        rgba($cyan-turquoise, 0.5) 100%
    );
}

@mixin pink-purple-border-gradient-tlbr {
    border-image-slice: 1;
    border-image-source: linear-gradient(
        5deg,
        rgba($pink, 0.5) 20%,
        $purple-light-opacity 50%,
        rgba($pink, 0.5) 100%
    );
}

@mixin pink-purple-border-gradient-bt {
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, $pink 0%, #8b00e9 20%, #6e00fd 66%, $purple 100%);
}

// Shadow
@mixin cyan-shadow {
    filter: drop-shadow(0 0 4px $cyan-turquoise);
}
@mixin pink-shadow {
    filter: drop-shadow(0 0 4px $pink);
}

// Hover shadows
@mixin cyan-hover-shadow {
    filter: drop-shadow(0px 0px 8px $cyan);
}
@mixin turquoise-hover-shadow {
    filter: drop-shadow(0px 0px 8px $turquoise);
}
@mixin cyan-turquoise-hover-shadow {
    filter: drop-shadow(0px 0px 8px $cyan-turquoise);
}
@mixin pink-hover-shadow {
    filter: drop-shadow(0px 0px 8px $pink);
}
@mixin white-hover-shadow {
    filter: drop-shadow(0px 0px 8px $white);
}

// Input text
@mixin input-text-normal {
    background: $very-dark-2;
    border: 0.5px solid $grey-dark !important;
    color: $grey-dark;
    padding: 15px 12px;
    font-size: 0.9rem;
    min-width: 240px;
    @media (max-width: 767px) {
        font-size: 1.2rem;
    }
}
@mixin input-text-disabled {
    @include input-text-normal;
    background: initial;
}

@mixin big-slider-font {
    font-size: $big-slider-font-size;
    line-height: $big-slider-line-height;
}

// Fonts
$font-light: 'Roboto Condensed Light', 'Roboto', sans-serif;
$font-medium: 'Roboto Condensed Bold', 'Roboto', sans-serif;
$font-regular: 'Roboto Condensed Regular', 'Roboto', sans-serif;
$font-bold: 'Roboto Condensed Bold', 'Roboto', sans-serif;
$font-fa: $fa, sans-serif;
