.block.newsletter {
    .newsletter-content {
        width: $container-lg;
        .field-wrapper {
            width: 90%;
            margin: auto;
            text-align: center;

            input[type='email'] {
                width: 280px !important;
            }
        }
    }
}
