.catalog-product-view {
    .crosssell-wrapper {
        .crosssell-product-list-items {
            grid-template-rows: auto;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .block.upsell {
        fieldset {
            grid-template-rows: auto;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
