@mixin fa-common-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin fa-linear-gradient-pink {
    @include pink-gradient;
    @include fa-common-gradient;
}

@mixin fa-linear-gradient-turquoise-blue {
    @include turquoise-blue-gradient;
    @include fa-common-gradient;
}

@mixin fa-linear-gradient-blue-turquoise {
    @include blue-turquoise-gradient;
    @include fa-common-gradient;
}

@mixin fa-linear-purple-pink-gradient {
    @include purple-pink-gradient;
    @include fa-common-gradient;
}

// Linear gradient
.fill-linear-gradient-pink-purple-light:before {
    @include fa-linear-gradient-pink;
}
.fill-linear-gradient-turquoise-blue:before {
    @include fa-linear-gradient-turquoise-blue;
}
.fill-linear-gradient-blue-turquoise:before {
    @include fa-linear-gradient-blue-turquoise;
}

// Drop shadow

.cyan-hover-shadow {
    @include cyan-hover-shadow;
}

.turquoise-hover-shadow {
    @include turquoise-hover-shadow;
}

.cyan-turquoise-hover-shadow {
    @include cyan-turquoise-hover-shadow;
}

.pink-hover-shadow {
    @include pink-hover-shadow;
}

.white-hover-shadow {
    @include white-hover-shadow;
}
