body.contentmanager-contenttype-homepage {
    .homepage {
        .block-home {
            margin-top: 36px;
            // height / bloc = hauteur du parent - ( sommes des margin + margin bottom de la dernière carte de bestsale) divisé par 4 bloc
            height: calc((100% - (36px * 3 + 3rem + 1rem)) / 4);
        }

        .slideshow {
            .slide {
            }

            .splide {
                &__list {
                    @media (max-width: 1679px) {
                        max-height: 410px;
                    }
                }
            }
        }

        .bestsellers {
            .bestsellers-row {
                .product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc((100% - 148px) / 2);
                        }

                        .product-item-inner-long {
                            display: block;
                        }
                    }
                }
            }
        }

        .availablenow {
            .availablenow-row {
                .availaible-product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc((100% + 143px) / 2);
                        }

                        .product-item-inner-long {
                            display: block;
                        }
                    }

                    .product-link-btn {
                        display: none;
                    }
                }
            }

            .availablenow-information-hovered {
                display: flex;
                flex-direction: column;
                text-align: justify;
                padding: 10px;
            }
        }
    }
}
