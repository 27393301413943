.coming-soon {
    &-info {
        &-countdown {
            .cm-countdown {
                gap: 0 6px;

                .block-available {
                    flex: 0 0 auto;
                }
            }
        }

        &-actions {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        &-title {
            padding-top: 8px;
        }


        &-description {
                display: block;
        }
    }

    &-image {
        min-height: 180px;
    }
}
