// Container size
@mixin container-sizing($container-size) {
    body {
        .page-wrapper {
            overflow-x: hidden;
            width: 100%;
            header.page-header {
                .panel.wrapper {
                    .panel.header {
                        max-width: $container-size;
                        margin: 0 auto;
                    }
                }
                .header.content,
                .navigation-container {
                    max-width: $container-size;
                    margin: 0 auto;
                }
            }
            main.page-main {
                width: 100%;
                max-width: $container-size;
                padding: 0;
            }
            footer.page-footer {
                @media screen and (max-width: 990px) {
                    margin-top: 0;
                }
                .footer.content {
                    max-width: 100vw;
                    margin: 0 auto;
                    padding-top: 0;
                    @media screen and (max-width: 990px) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

//Common typo mixins
@mixin normal-font {
    font-size: $normal-font-size;
    line-height: initial;
}

@mixin small-font {
    font-size: $small-font-size;
    line-height: initial;
}

// Absolute centring
@mixin absolute-centring {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-horizontal-centring {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@mixin absolute-vertical-centring {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// Accessible hidden text
@mixin txt-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin font-awesome {
    font-family: $font-fa;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin background-color {
    background: $dark-1 0% 0% no-repeat padding-box;
}

@mixin active-button {
    color: $grey;
    border-color: $very-dark-2;
    box-shadow: none;
    background: none;
    background-color: $very-dark-2;
    text-shadow: none;
}

@mixin border-block {
    @include background-color;
    box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
    -moz-box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
    -webkit-box-shadow: 1px 1px 14px $black, -1px -1px 6px rgba(248, 247, 242, 0.15);
    border: 2px solid $dark-1;

    @media (max-width: 1024px) {
        border: 2px solid $dark-1;
    }
}

@mixin border-block-available {
    @include background-color;
    border: 2px solid $dark-1;

    @media (max-width: 1024px) {
        border: 2px solid $dark-1;
    }
}

@mixin border-left {
    border-left: 1px solid $light-dark;
    padding-left: 0.8rem;
}

@mixin border-top {
    border-top: 1px solid $light-dark;
    padding-top: 0.8rem;
}

@mixin border-bottom {
    border-bottom: 1px solid $light-dark;
    padding-bottom: 0.8rem;
}

@mixin full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

@mixin prefixer($property, $value, $prefixes) {
    @each $prefix in $prefixes {
        @if $prefix == webkit {
            @if $prefix-for-webkit {
                -webkit-#{$property}: $value;
            }
        } @else if $prefix == moz {
            @if $prefix-for-mozilla {
                -moz-#{$property}: $value;
            }
        } @else if $prefix == ms {
            @if $prefix-for-microsoft {
                -ms-#{$property}: $value;
            }
        } @else if $prefix == o {
            @if $prefix-for-opera {
                -o-#{$property}: $value;
            }
        } @else if $prefix == spec {
            @if $prefix-for-spec {
                #{$property}: $value;
            }
        } @else {
            @warn "Unrecognized prefix: #{$prefix}";
        }
    }
}

// Same css styles for all white-background image overlay
@mixin background-overlay {
    background-image: radial-gradient(
        50% 50%,
        rgba(55, 62, 62, 0) 33%,
        rgba(55, 62, 62, 0.06) 66%,
        rgba(55, 62, 62, 0.21) 100%
    );
    margin: 0px;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 100;
}

// Same css styles for all packshot white-background images
@mixin white-background-img {
    height: 155px;
    background: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;

    .background-overlay {
        @include background-overlay;
    }

    img {
        max-height: 100%;
        aspect-ratio: initial;
        max-height: 90%;
        width: initial;
        object-fit: initial;
    }
}

// Same css styles for all packshot dark-background images
@mixin dark-background-img {
    height: 155px;
    background: #232324;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
        aspect-ratio: initial;
        max-height: 90%;
        width: initial;
        object-fit: initial;
    }
}

// Same css styles for all product - item - small
// Exept for price, html is too different beetwen ko and phtml
@mixin product-item-small {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    .product-item-photo {
        .product-image-wrapper {
            height: 100%;
            width: 120px;
            padding: 0 !important; // important to overide inline css generated code
            max-height: 85px;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .packshot-white-background {
            @include white-background-img;
        }
        .packshot-dark-background {
            @include dark-background-img;
        }
        .ribbon-inner {
            padding: 6px 9px;
        }
    }
    .platforms-icons {
        vertical-align: top;
        display: inline-block;
        margin: 0;
        font-size: 15px;
        height: initial;
        line-height: 18px;
        .platforms-icon {
            padding-right: 0px;
            display: inline-block;
            i {
                font-size: 15px;
                line-height: 1.1rem;
                color: $grey;
            }
            span,
            .platform-separator {
                @media screen and (max-width: $screen-xxs) {
                    display: none;
                }
            }
            .platform-separator {
                margin: 0px 3px 0px -3px;
            }
        }
    }
    .title-separator {
        display: none;
    }
    .product-item-details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: none;
        width: calc(100% - 120px);
        // 100% de la taille du parent - la largeur de .product-item-photo
        padding: 10px 0px 4px;

        > * {
            margin: 0px 6px;
            @media screen and (min-width: $screen-xxs) {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                height: 33px;
            }
        }

        @media screen and (min-width: $screen-xxs) {
            > * {
                margin: 0px 9px;
            }
        }

        .price-cart-button {
            display: flex;
            padding-bottom: 0.25rem;
            .price-box {
                padding-right: 0.25rem;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                span {
                    font-family: $font-regular;
                }
                > span,
                > span.promo-percent {
                    padding: 9px 3px 3px;
                    vertical-align: initial;
                }
                .promo-percent {
                    &:after,
                    &:before {
                        content: initial;
                    }
                    font-size: 0.8rem;
                    padding: 0.66rem 0.5rem;
                    span {
                        vertical-align: initial;
                        letter-spacing: 0.5px;
                        color: $pink;
                    }
                }
                .special-price {
                    vertical-align: bottom;
                }
                .old-price {
                    display: none;
                }
                .price-container {
                    display: table-cell;
                    vertical-align: middle;
                    padding-right: 0.25rem;
                }
                .weee .price {
                    margin-left: 0px;
                }
            }
        }
    }
    .product-item-inner {
        &.product-item-inner-long {
            .product-item-actions {
                .actions-primary {
                    button {
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
        .product-item-actions {
            .actions-primary {
                button {
                    height: 2.5rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 1rem;
                    width: 80px;
                }
                .stock.unavailable {
                    &.disabled-btn {
                        display: none;
                    }
                }
            }
            .actions-secondary {
                display: none;
            }
        }
    }
    .product-item-name {
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        display: block;
        hyphens: auto;
        word-wrap: break-word;
        background: $very-dark-1 0% 0% no-repeat padding-box;
        border-top: 3px solid $dark-1;
        // - 9px * 2 cause of padding
        padding: 5px 0px;
        flex: 2 0 auto;
        font-size: 14px;
        width: 100%;
        .product-item-link {
            margin: 0px 6px;
            display: block;
            .product-name,
            .product-tags {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                margin: 0 3px 0 0;
                padding: 0;
                font-size: 15px;
            }
            .product-tags {
                white-space: nowrap;
                max-width: 100%;
            }
            .product-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                font-family: $font-regular;
                line-height: initial;
            }
        }
    }
    .wishlist-button {
        margin: auto;
    }
}
@mixin availablenow-product-item-small {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    .product-item-photo {
        .product-image-wrapper {
            height: 100%;
            width: 120px;
            padding: 0 !important; // important to overide inline css generated code
            max-height: 85px;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .packshot-white-background {
            @include white-background-img;
        }
        .packshot-dark-background {
            @include dark-background-img;
        }
        .ribbon-inner {
            padding: 6px 9px;
        }
    }
    .platform-icon-default {
        background: $blue;
        padding: 3px 10px;
        color: $white;
    }
    .platform-icon-logo-default {
        color: $white !important;
    }
    .platforms-icons {
        vertical-align: top;
        display: inline-block;
        margin: 0;
        font-size: 15px;
        height: initial;
        line-height: 18px;
        .platforms-icon {
            display: inline-block;
            i {
                font-size: 15px;
                line-height: 1.1rem;
                color: $grey;
            }
            span,
            .platform-separator {
                @media screen and (max-width: $screen-xxs) {
                    display: none;
                }
            }
            .platform-separator {
                margin: 0px 3px 0px -3px;
            }
        }
    }
    .title-separator {
        display: none;
    }
    .product-item-details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: none;
        width: calc(100% - 286px);
        // 100% de la taille du parent - la largeur de .product-item-photo
        padding: 10px 0px 4px;

        > * {
            margin: 0px 6px;
            @media screen and (min-width: $screen-xxs) {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                height: 33px;
            }
        }

        @media screen and (min-width: $screen-xxs) {
            > * {
                margin: 0px 9px;
            }
        }

        .price-cart-button {
            display: flex;
            padding-bottom: 0.25rem;
            .price-box {
                padding-right: 0.25rem;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                span {
                    font-family: $font-regular;
                }
                > span,
                > span.promo-percent {
                    padding: 9px 3px 3px;
                    vertical-align: initial;
                }
                .promo-percent {
                    &:after,
                    &:before {
                        content: initial;
                    }
                    font-size: 0.8rem;
                    padding: 0.66rem 0.5rem;
                    span {
                        vertical-align: initial;
                        letter-spacing: 0.5px;
                        color: $pink;
                    }
                }
                .special-price {
                    vertical-align: bottom;
                }
                .old-price {
                    display: none;
                }
                .price-container {
                    display: table-cell;
                    vertical-align: middle;
                    padding-right: 0.25rem;
                }
                .weee .price {
                    margin-left: 0px;
                }
            }
        }
    }
    .product-item-inner {
        &.product-item-inner-long {
            .product-item-actions {
                .actions-primary {
                    button {
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
        .product-item-actions {
            .actions-primary {
                button {
                    height: 2.5rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 1rem;
                    width: 80px;
                }
                .stock.unavailable {
                    &.disabled-btn {
                        display: none;
                    }
                }
            }
            .actions-secondary {
                display: none;
            }
        }
    }
    .product-item-name {
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        display: block;
        hyphens: auto;
        word-wrap: break-word;
        background: $very-dark-1 0% 0% no-repeat padding-box;
        border-top: 3px solid $dark-1;
        border-right: 3px solid $dark-1;
        // - 9px * 2 cause of padding
        padding: 8px 0px;
        flex: 2 0 auto;
        font-size: 14px;
        width: 100%;
        .product-item-link {
            margin: 0px 6px;
            display: block;
            .product-name,
            .product-tags {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                margin: 0 3px 0 0;
                padding: 0;
                font-size: 15px;
            }
            .product-tags {
                white-space: nowrap;
                max-width: 100%;
            }
            .product-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                font-family: $font-regular;
                line-height: initial;
            }
        }
    }
    .wishlist-button {
        margin: auto;
    }
}

// Same css styles for all product-item (actually only home & plp)
@mixin product-item-image-zoom {
    z-index: 1;

    // &:hover {
    &.hovered {
        z-index: 3;

        // wraper / container styles for packshot colored background

        .product-item-info + .product-image-zoomed-wrapper.packshot-white-background {
            @include white-background-img;
        }

        .product-item-info + .product-image-zoomed-wrapper.packshot-dark-background {
            @include dark-background-img;
        }

        .product-item-info + .product-image-zoomed-wrapper.packshot-white-background,
        .product-item-info + .product-image-zoomed-wrapper.packshot-dark-background {
            img {
                max-height: 84%;
                z-index: -2;
            }
        }

        // wraper / container styles
        .product-item-info + .product-image-zoomed-wrapper {
            position: absolute;
            z-index: -2;
            height: 100% !important;
            max-width: initial;
            height: 100%;
            width: 540px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
            border: 2px solid $dark-1;
            border-width: 2px 0px 2px 2px;

            // animation set
            opacity: 0;
            top: 0px;
            right: 0px;
            left: initial;
            animation: slide-left 0.3s forwards;

            // overlay progressif
            .image-zoomed-overlay {
                display: block;
                z-index: 1;
                position: absolute;
                height: 100%;
                width: 150px;
                background: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.3) 60%,
                    rgba(0, 0, 0, 0.6) 90%
                );

                // animation/position set
                top: 0;
                left: -150px;
                right: 0px;
                left: initial;
            }
        }

        // wraper / container styles for packshot-cover
        .product-item-info + .product-image-zoomed-wrapper.packshot-cover {
            display: block;

            // Darker overlay for packshot cover
            .image-zoomed-overlay {
                background: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.6) 60%,
                    rgba(0, 0, 0, 0.81) 90%
                );
                width: 210px;
            }

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 616/353;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
    }

    // Adapt code to make zoomed-image slide form left to right
    &.hovered--toright {
        .product-item-info + .product-image-zoomed-wrapper {
            right: initial;
            left: 0;
            animation: slide-right 0.3s forwards;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.9);
            border-width: 2px 2px 2px 0px;
        }

        .product-item-info + .product-image-zoomed-wrapper.packshot-cover .image-zoomed-overlay {
            background: linear-gradient(
                270deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.6) 60%,
                rgba(0, 0, 0, 0.81) 90%
            );
        }

        // overlay progressif
        .product-item-info + .product-image-zoomed-wrapper .image-zoomed-overlay {
            background: linear-gradient(
                270deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.3) 60%,
                rgba(0, 0, 0, 0.6) 90%
            );
            left: 0px;
            right: initial;
        }
    }
}
